/*!
 * Dashcore 2.0.1 (https://themeforest.net/user/5studios)
 * Sass & Startup HTML Template 
 * Main CSS file
 * Copyright 2011-2019 The 5studios.net team (https://5studios.net)
 *
 * [ TABLE OF CONTENTS ]
 *
 * 1. RESET STYLES
 * 2. NAVIGATION
 *   2.1 - Navigation base styles
 *   2.2 - Pills
 * 3. FORMS
 *   3.1 - Form
 *   3.2 - Buttons
 * 4. UTILITIES
 *   4.1 - Background
 *   4.2 - Border
 *   4.3 - Devices
 *   4.4 - Lists
 *   4.5 - Misc
 *   4.6 - Overlay
 *   4.7 - Position
 *   4.8 - Responsive
 *   4.9 - Spacing
 *   4.10 - Text
 *   4.11 - Type
 * 5. GENERAL CONTENT STYLES
 *   5.1 - Page Loader
 *   5.2 - Mockup
 *   5.3 - Icons
 * 6. SECTIONS
 *   6.1 - General Styles
 *   6.2 - Testimonials
 *   6.3 - Footer
 * 7. PRICING
 *   7.1 - General styles
 *   7.2 - Pricing heading
 * 8. PAGE HEADING
 * 9. CUSTOM
 */

// User predefined variables, will overwrite any predefined variable
@import "user/variables";

// For compatibility and sharing of styles with another themes of ours
@import "shared/import";

// Importing of any local variable|function|mixins that should be globally accessed
@import "dashcore/import";

// Bootstrap core styles
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

// The local theme
@import "shared/styles";
@import "dashcore/styles";

// User custom styles
@import "user/styles";

/* Demo styling, 
 * this file will bring Demo Landing Page styles, 
 * you can safely remove if you're not using any style the index.html
 */
@import "dashcore/demo";
