section.template-selection {

  /*  min-height: 200px;
    overflow: auto;*/

  .highlight {
    background-color: yellow;
  }

  .upload-logo-group {
    margin-top: 2rem;
  }

  .accordion-info {
    .card-header {
      background-color: $primary-color-2;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .badge {
        display: none;
      }

      &.selected {
        .badge {
          display: inline-block;
          height: 22px;
          color: lightyellow;
          border: 1px solid lightyellow;
          margin-top: 7px;

        }
      }

    }
  }

  /* [data-toggle="collapse"].collapsed .angle2 {
     transform: rotate(90deg);
   }
   [data-toggle="collapse"] .angle2 {
     transition: transform 0.3s ease;
     margin-right: 0.5rem;
   }*/

  .chooser_container {
    margin: auto;
    margin-bottom: .4rem;
  }

  .card-body {
    height: auto !important;
    border: 0;

    &.selected {
      border: 1px solid #000;
      //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 0, 255, 0.5); /* Adjust the color and blur radius as needed */
      background-color: lightyellow;
    }
  }

  .template-card {

    &:hover {
      box-shadow: none;
    }

    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 17px;
    }

    a {
      input {
        position: relative;
        left: -5px;
      }
    }

    .tagline {
      margin-top: .3rem;
      display: inline-block;
    }

    /*border: 1px solid #ccc;
    border-radius: 5px;*/
    border: 0;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    &.selected {
      border: 1px solid #000;
    }

  }


  .color-picker-group {

    .square .clr-field input, .circle .clr-field input {
      padding-left: 36px;
    }

    .clr-field button {
      background-image: repeating-linear-gradient(45deg,#aaa 25%,transparent 25%,transparent 75%,#aaa 75%,#aaa),repeating-linear-gradient(45deg,#aaa 25%,#fff 25%,#fff 75%,#aaa 75%,#aaa);
      background-position: 0 0,4px 4px;
      background-size: 8px 8px;
    }

    .square .clr-field button {
      width: 22px;
      height: 22px;
      left: 5px;
      right: auto;
      border-radius: 5px;
    }

    .clr-field button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      padding: 0;
      border: 0;
      color: inherit;
      text-indent: -1000px;
      white-space: nowrap;
      overflow: hidden;
      pointer-events: none;
      cursor: pointer;
    }


    .clr-field input {
      margin: 0;
      direction: ltr;
    }

    input {
      width: 150px;
      height: 32px;
      padding: 0 10px;

      border: 1px solid #ccc;
      border-radius: 5px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      box-sizing: border-box;
    }

  }

}