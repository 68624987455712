/* ====== Magnific Popup Zoom effect ====== */
.mfp-zoom-in {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== Newspaper effect ======
*/
.mfp-newspaper {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.5s;

        transform: scale(0) rotate(500deg);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1) rotate(0deg);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0) rotate(500deg);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== Move-horizontal effect ======
*/
.mfp-move-horizontal {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s;

        transform: translateX(-50px);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateX(0);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: translateX(50px);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== Move-from-top effect ======
*/
.mfp-move-from-top {
    .mfp-content {
        vertical-align:top;
    }

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s;

        transform: translateY(-100px);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.2s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateY(0);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: translateY(-50px);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== 3d unfold ======
*/
.mfp-3d-unfold {
    .mfp-content {
        perspective: 2000px;
    }

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform-style: preserve-3d;
        transform: rotateY(-60deg);
    }


    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: rotateY(0deg);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: rotateY(60deg);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== Zoom-out effect ======
*/
.mfp-zoom-out {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1.3);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(1.3);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }

    }
}

/*
====== for image zoom animation ======
*/
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/* Misc for using with images */

/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
    padding: 0;
}

/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
    top: 0;
    bottom: 0;
}

/* padding for main container */
.mfp-no-margins .mfp-container {
    padding: 0;
}