// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Font Awesome
//@import "node_modules/font-awesome/scss/font-awesome";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

//@import '~swiper/src/swiper';
@import 'swiper/scss';
@import '~aos/src/sass/aos';

// bootstrap theme
@import "components/dashcore/theme";

// pricing table
@import "components/pricing";
@import "components/signup";
@import "components/wizard/brands";
@import "components/wizard/payment-link";
@import "components/wizard/templates";
@import "components/wizard/products";
@import "components/wizard/credit-card";





// Variables
//@import "variables";

// Custom CSS
//@import "custom";

// Page Specific
//@import "components/payment";
//@import "components/contact";
//@import "pages/signup";
//@import "pages/pricing";
