section.product-selection {

  /*  min-height: 200px;
    overflow: auto;*/

  .highlight {
    background-color: yellow;
  }

  .upload-logo-group {
    margin-top: 2rem;
  }

  .accordion-clean {
    .card-header {
      // background-color: $primary-color-2;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .card-title {
        color: #333;
        font-weight:bold;
      }


      .price {
        position: relative;

        .amount {
          color: $primary-color-2;
          font-weight: 400;
        }

        .interval {
          position: absolute;
          width: 100px;
          top: 21px;
          right: -15px;
          font-size: 13px;
          text-align: right;
        }
      }

      .badge {
        display: none;
      }

      &.selected {
         position: relative;
        .badge {
          position: absolute;
          right:7rem;
          display: inline-block;
          height: 22px;
          color: $primary-color-1;
          border: 1px solid $primary-color-1;
          margin-top: 7px;
          line-height: .9rem;

        }
      }

    }
  }

  /* [data-toggle="collapse"].collapsed .angle2 {
     transform: rotate(90deg);
   }
   [data-toggle="collapse"] .angle2 {
     transition: transform 0.3s ease;
     margin-right: 0.5rem;
   }*/

  .chooser_container {
    margin: auto;
    margin-bottom: .4rem;
  }

  .card-body {
    height: auto !important;
    border: 0;

    &.selected {
      border: 1px solid #000;
      //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 0, 255, 0.5); /* Adjust the color and blur radius as needed */
      background-color: lightyellow;
    }
  }

  .template-card {

    &:hover {
      box-shadow: none;
    }

    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 17px;
    }

    a {
      input {
        position: relative;
        left: -5px;
      }
    }

    .tagline {
      margin-top: .3rem;
      display: inline-block;
    }


    /*border: 1px solid #ccc;
    border-radius: 5px;*/
    border: 0;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    &.selected {
      border: 1px solid #000;
    }

  }


}