/* ==========================================================================
    [1. RESET STYLES]
========================================================================== */
body {
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  transition: all 0.3s ease-in;

  &,
  &:focus,
  &:active,
  &:hover {
    outline: 0 !important;
    text-decoration: none;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $border-color;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/* overrides normalize.css default style */
fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
