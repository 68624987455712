
div.tree-multiselect {
  .auxiliary {
    .select-all-container {
      display: block !important;
    }
  }

  .selected {
    height:auto;
    max-height:380px;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size:.9rem;

    .section-name {
      display: none;
    }
  }

  .section {
    border-top: 2px solid #D8D8D8;
    height:300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 5px;

    .title {
      display: none;
    }

    .item {
      padding-left: 0 !important;
      margin-left: 0 !important;
      font-size:.9rem;
    }
  }
}


.brands-group {

  .labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    /* label {
       flex: 1 1 50%;
       margin-bottom: 0;
       &:first-child {
         margin-right: 1rem;
       }
     }*/
  }


}