$plan-break-point: 988px;

.pricing-header {
  p {
    color: #000;
    font-weight: 500;
  }

  .container {
    padding-bottom: 0;
  }
}

.signup-via-email-cont {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px; // Add padding on both sides

  .input-group-register {
    width: 100%;
    display: flex;
    flex-direction: row;

    input[type="email"] {
      width: 100%;
      max-width: none; // Reset max-width for the input
      border-color: $button-color;

      &::placeholder {
        font-weight: 500;
        font-size: 1rem;
      }
    }

    button {
      /* Add flex-grow to prevent button from expanding */
      flex-grow: 0;
      width: auto; /* Auto width based on button content */
      max-width: none; /* Remove max-width to ensure full width */
      background-color: $button-color;
      border-color: $button-color;
    }

    .form-control {
      padding: 2rem;
    }
  }

  // Media query for mobile responsiveness
  @media (max-width: 768px) {
    .input-group-register {
      flex-direction: column; // Stack elements vertically on mobile

      button {
        position: static; /* Remove absolute positioning */
        top: auto; /* Reset top */
        transform: none; /* Reset transform */
        right: auto; /* Reset right */
        margin-top: 1rem;
        padding: 1rem;
        text-align: center;
      }

    }


    padding: 0 10px; // Reduce padding on mobile screens
  }
}


// page wide mods when in mobile
@media (max-width: $plan-break-point) {
  .container-sm, .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }

  .pricing-plans > .container {
    padding-bottom: 0;
  }

  /*.pricing-comparison > .container {
    padding-top: 0;
  }*/

}

section.pricing-plans {

  .container > .section-heading {
    margin-bottom: 0;
  }

  .card-body {
    //height: auto;
    position: relative; // Position the button 2rem from the bottom of the card-body
    height: 5rem;

    button {
      /* Position the button 2rem from the bottom of the card-body */
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);

    }

  }

  .primary-top-cta-cont {
    display: flex;
    justify-content: center;

    button {
      background-color: #f0ad00 !important;
      border: 0;
    }
  }


  .plans-row {
    .plans-col {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch
    }

    .card {
      flex: 1; /* Distribute equal space to each card within the container */
      border: 0;
      text-align: left !important;

      .pricing-badge {
        transform: translateY(-50%);

        margin: auto;
        position: absolute;

        background-color: $button-color;
        font-size: 95%;
        text-transform: uppercase;

        @media (max-width: $plan-break-point) {
          position: static; /* Remove absolute positioning */
          top: auto; /* Reset top */
          transform: none; /* Reset transform */
          left: auto; /* Reset right */
          border-radius: 0;

          /* Adjust the badge styles to extend to both sides of the screen */
          width: calc(100% + 45px);
          position: relative;
          left: -15px; /* Adjust this value as needed to offset the padding */
          right: -15px; /* Adjust this value as needed to offset the padding */
          margin-left: -15px; /* Offset the left margin */
          margin-right: -15px; /* Offset the right margin */
          margin-bottom: .5rem;
          top: -1rem;
          padding: .3rem;


        }

        &.badge-direct-dropship {
          color: $button-color;
          border-color: $button-color;
          background-color: #fff !important;
          left: 25%;
          width: 270px;
          @media (max-width: $plan-break-point) {
            left: 0;
            width: calc(100% + 45px);
          }
        }

        &.badge-most-popular {
          background-color: $button-color;
          left: 34%;
          width: 150px;

          @media (max-width: $plan-break-point) {
            left: 0;
            width: calc(100% + 45px);
          }

        }

      }


      .card-header {
        align-items: flex-start !important;
        padding: 0;
        background: 0;

        .contact-sales {
          font-size: 20px;
          margin: 1.5rem;
        }
      }


      .list-group {
        border-bottom: 0;
      }

      .list-group-item {
        padding: 0;
        margin-top: 1rem;
        border: 0;

        display: flex;
        align-items: center; /* Vertically center align items */

        .tick {
          font-size: 20px; /* Adjust the font size as needed */
          vertical-align: middle; /* Vertically align the tick mark to the middle */
          margin-right: 10px; /* Add some spacing between the tick and text */
        }

      }

    }

    // when not in mobile
    @media (min-width: $plan-break-point) {
      .plans-col {
        margin-top: 3rem !important;

        &.plan-basic {
          .card {
            border: 2px solid $button-color;
          }
        }
      }
      .card {
        text-align: center !important;
        border: 1px solid rgba(22, 28, 45, 0.125);

        .card-header {
          align-items: center !important;
          padding: 1.75rem 1.25rem;
          background: linear-gradient(#fcfdfe, #f9fbfd);

        }

        .list-group-item {
          display: block;
          padding: 0.75rem 1.25rem;
          margin-top: 0;
          //border: 1px solid rgba(22, 28, 45, 0.125);
          //border-width: 0 0 1px;
          border: 0;

          .tick {
            display: none;
          }

        }

        .card-body {
          .buy-now-sm {
            display: none;
          }

          .buy-now-lg {
            font-size: 1.0rem;
            display: inline-block;
            width: 20rem;
            text-align: center;
          }
        }
      }
    }


    // when in mobile
    @media (max-width: $plan-break-point) {
      .plans-col {
        flex: none; // turn off .col-md-6 boostrap class
        max-width: 100%; // turn off .col-md-6 boostrap class

        // hide all plans but first
        &:not(:first-child) {
          display: none;
        }
      }

      .card-body {
        text-align: center;

        .buy-now-sm {
          display: none;
        }

        .buy-now-lg {
          //margin-top: 1rem;
          display: inline-block;

          // turn off displaying the button always at the bottom, a fix to make the buttons align up in desktop mode as flexbox it making the height of the boxes equal, and messing up the alignment
          position: static;
          bottom: unset;
          left: unset;
          transform: none;

        }
      }
    }

    .pricing-value {
      margin-left: 1rem;
    }

    .pricing {

      h4 {
        font-weight: 600;
      }
    }

  }

  .plan-buttons {

    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-inline-start: 0;

    li {
      list-style: none;
      //flex: 1;
      flex-grow: 1;


      button {
        width: 100% !important;
        border: 1px solid #DDD;
        border-right: 0;
        //border-top: 0;
        padding: 10px;
        background: #FFF;
        font-size: 14px;
        font-weight: bold;
        height: 60px;
        color: #999;

        /* & li.active {
           background: #F5F5F5;
           color: #000;
         }*/
      }


      /*.active {
        button {
          background: #F5F5F5;
          color: #000;

        }
      } */

      &:last-child {
        border-right: 1px solid #DDD;
      }
    }

    li.active button {
      background: #F5F5F5;
      color: #000;
      border-top: 5px solid #f0ad00;
      border-bottom: 1px solid #fff;
    }


    .bg-grey {
      //border-top: 3px solid blue;

      background-color: #fff;
    }

    .bg-lblue {
      //border-top: 3px solid blue;
      background-color: #efefef;
    }

    @media (min-width: $plan-break-point) {
      display: none;
    }
  }


}

section.pricing-comparison {

  > .container {
    // page wide mods when in desktop
    @media (min-width: $plan-break-point) {
      padding-top: 0;
    }
  }

  // page when in mobile
  @media (max-width: $plan-break-point) {
    .pricing-comparison-actual {
      display: none;
    }
  }
  .pricing-comparison-actual {
    display: none;
  }


  /* Responsive pricing table CSS */


  //$color_pricing_enterprise_bg: rgba(240, 173, 0,.06);
  $color_pricing_enterprise_bg: #fff;
  $color_pricing_black: #34313f;
  $color_pricing_blue_fg: #4b71fc;
  /*
  - make mobile switch sticky
  */
  /** {
    box-sizing:border-box;
    padding:0;
    margin:0;
    outline: 0;
  }
  body {
    font-family:Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size:14px;
    padding:14px;
  }*/

  .compare-toggle-cont {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center horizontally */



    .compare-plan-features {
      z-index: 1;
      border-color: $button-color;
      color: black;
      text-align: center;

      &:hover {
        box-shadow: 0 1px 10px rgba(75, 113, 252, 0.4) !important;
        background-color: $button-color;
      }

      &:focus {
        box-shadow: 0 1px 10px rgba(75, 113, 252, 0.4) !important;
      }

      // on mobile
      @media (max-width: $plan-break-point) {
        width:100%;
        color: black;
        background-color: $button-color;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        padding-inline-start: 0;
      }

      .minus {
        display: none
      }
    }
  }


  .features-section {
    h4 {
      font-size: 1.125rem;
      font-weight: 400;
      margin-bottom: 0;
    }

    span.feature-text {
      color: #6b7177;
      font-size: .875rem;
    }

    .plan-value {

    }
  }


  article {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }

  ul {
    display: flex;
    top: 0px;
    z-index: 10;
    padding-bottom: 14px;
  }

  li {
    list-style: none;
    flex: 1;
  }

  li:last-child {
    border-right: 1px solid #DDD;
  }

  /*  button {
      width: 100% !important;
      border: 1px solid #DDD;
      border-right: 0;
      border-top: 0;
      padding: 10px;
      background: #FFF;
      font-size: 14px;
      font-weight: bold;
      height: 60px;
      color: #999
    }*/

  li.active button {
    background: #F5F5F5;
    color: #000;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  th {
    background: #FFF;
    display: none;
  }

  td, th {
    height: 53px
  }

  td, th {
    //border:1px solid #DDD;
    padding: 10px;
    empty-cells: show;
  }

  td, th {
    text-align: left;
  }

  td + td, th + th {
    text-align: center;
    display: none;
  }

  td.default {
    display: table-cell;
  }

  .bg-grey {
    //border-top: 3px solid #5E6A71;
    border-bottom: 1px solid #fff;
    background-color: #fff;
  }

  .bg-ltpurple {
    border-top: 3px solid #b197b7;
    border-bottom: 1px solid #f4eef6;
    background-color: #f4eef6
  }

  .bg-purple {
    border-top: 3px solid #714e71;
    border-bottom: 1px solid #e2cee3;
    background-color: #e2cee3;
  }

  .bg-lblue {
    //border-top: 3px solid #714e71;

    background-color: $color_pricing_enterprise_bg
  }

  .bg-membership {
    Height: 10px !important;
    border-top: 3px solid #0097CF;
  }

  .sep {
    background: rgba(75, 113, 252, .06);
    font-weight: bold;
    color: $color_pricing_black;
    font-size: 16px;
    //border-top:1px solid #b079b1;
  }

  td.short {
    height: 20px !important;
    border-bottom: 1px solid #fff;
    background-color: #fff;
  }

  td.shortltpurple {
    height: 20px !important;
    border-bottom: 1px solid #f4eef6;
    background-color: #f4eef6;
  }

  td.shortpurple {
    height: 20px !important;
    border-bottom: 1px solid #e2cee3;
    background-color: #e2cee3;
    display: table-cell;
  }

  td.shortblue {
    height: 20px !important;

    background-color: $color_pricing_enterprise_bg;
    display: table-cell;
  }

  td.price {
    height: 20px !important;
    border-top: 0px;
    border-bottom: 0px;
    text-align: center;
  }

  td.priceltpurple {
    height: 20px !important;
    border-top: 0px;
    border-bottom: 0px;
    text-align: center;
    background-color: #f4eef6;
  }

  td.pricepurple {
    height: 20px !important;
    border-top: 0px;
    border-bottom: 0px;
    text-align: center;
    background-color: $color_pricing_enterprise_bg;
    display: table-cell;
  }

  td.priceblue {
    height: 20px !important;
    border-top: 0px;
    border-bottom: 0px;
    text-align: center;
    background-color: #e2cee3;
    display: table-cell;
  }

  td.priceheader {
    height: 20px !important;
    border: 0;
    font-size: 25px;
    font-weight: bold;
  }

  td.description {
    height: 20px !important;
    border-top: 0px;
    text-align: center;
    border-bottom: 0px;
  }

  td.descriptionltpurple {
    height: 20px !important;
    border-top: 0px;
    text-align: center;
    background-color: #f4eef6;
    border-bottom: 0px;
  }

  td.descriptionpurple {
    height: 20px !important;
    border-top: 0px;
    text-align: center;
    background-color: $color_pricing_enterprise_bg;
    border-bottom: 0px;
    display: table-cell;
  }

  td.grey {
    background-color: #fff;
    color: #5e6a71;
  }

  td.ltpurple {
    background-color: #f4eef6;
    display: table-cell;
    color: $color_pricing_enterprise_bg;
  }

  td.plan-mobile-default {
    display: table-cell;
  }

  td.purple {
    background-color: $color_pricing_enterprise_bg;
    color: $color_pricing_black;
  }

  .txt-l {
    font-size: 28px;
    font-weight: bold;
  }

  /*--.txt-top { position:relative; top:-9px; left:-15px; }--*/
  .txt-top {
    position: relative;
    top: 15px;
  }

  .tick {
    font-size: 18px;
    color: $color_pricing_blue_fg;
  }

  .tickltpurple {
    font-size: 18px;
    color: #b079b1;
  }

  .tickltpurplesmallfont {
    font-size: 16px;
    color: #b079b1;
  }

  .tickpurple {
    font-size: 18px;
    color: $color_pricing_enterprise_bg;
  }

  .donotshow {
    border: 0;
    background: none;
  }

  .hidetop {
    border-top: 1px solid #ddd;
    border-bottom: 0px;
    background: none;
  }

  @media (min-width: $plan-break-point) {
    ul {
      display: none;
    }
    td, th {
      display: table-cell !important;
    }
    td, th {
      // first child has 60% width, the rest have 20%
      &:first-child {
        width: 60%;
      }

      width: 20%;

      //width: 280px;

    }
    td + td, th + th {
      //width: auto;
      width: 20%;
    }
  }

}