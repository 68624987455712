//
// user/styles.scss
// This file is mean to write your custom styling rules
// It is recommended you do this way and not by editing the Bootstrap or DashCore styling files directly because future updates

.gradient.gradient-purple-navy.overlay.alpha-8::after {
  background-image: linear-gradient(to right top, rgba(240, 173, 0, 0.8), rgba(0, 26, 62, 0.8));
}

.gradient.gradient-purple-navy.overlay.alpha-8::after {
  background-image: linear-gradient(to right top, rgba(236, 82, 35, 0.8), rgba(22, 124, 192, 0.8));
}

.gradient.gradient-purple-navy.overlay.alpha-8::after {
  background-image: linear-gradient(to right top, rgba(236, 82, 35, 0.8), rgba(28, 37, 64, 0.8));
}

.gradient.gradient-purple-navy.overlay.alpha-8::after {
  background-image: linear-gradient(to right top, rgba(121, 124, 131, 0.8), rgba(63, 70, 80, 0.8));
}

.gradient.gradient-purple-navy.overlay.alpha-8::after {
  background-image: linear-gradient(to right top, rgba(28, 107, 182, 0.8), rgba(0, 26, 62, 0.8));
}

.stroke-primary {
  stroke: #f78e1e !important;
}

.orange {
  color: #f0ad00 !important;
  font-size: 45px;
}

.type-spacing {
  height: 100px;
}

.navigation .logo {
  max-height: 100px;
  height: auto;
}

.logo {
  max-width: 300px;
  width: 300px;

  //image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

a.navbar-brand {
  //padding-top: 0;
  //padding-bottom: 0;
}


a.navbar-brand {
  max-width: 290px;
  width: 100%;
  height: auto;
  min-height: 70px;
  // max-height: 100px;
  //height: 100%;
}


@media (max-width: 768px) {
  .navbar-nav > li:first-child {
    flex: 70%;
  }

  .logo {
    max-width: 300px;
    width: 100%;

    //image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
  }

  .main-nav {
    padding: 0;
  }

}

footer.text-contrast a {
  color: #fff;
}

/*.text-secondary {
  color: #0c305b !important;
}*/

.text-primary {
  color: #0c305b !important;
}

.btn-primary {
  color: #ffffff;
  background-color: #0c305b !important;
  border-color: #0c305b !important;
}

.contact-form-submit-btn {
  width: 100%;

  &:hover {
    background-color: #1C6BB6CC !important;
  }

  background-color: #037dc5 !important;
  border-color: #1C6BB6CC !important;;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  white-space: normal;
  line-height: 1.5;
  text-align: center;
  font-size: 15px;
}

.btn-light {
  color: #161c2d !important;
  background-color: #f1f4f8 !important;
  border-color: #f1f4f8 !important;
  margin-right: 10px;

}


.manager-login {
  .text-dark, .text-darker, .text-secondary, .text-primary, .form-label {
    color: white !important;
  }

  .btn-primary {
    background-color: #f78e1e !important;
  }

  a.text-primary:hover, a.text-primary:focus {
    color: #f78e1e !important;
  }

  a.text-dark:hover,
  a.text-dark:focus {
    color: #f78e1e !important;
  }

  .help-block {
    margin-bottom: 10px;
    display: block;
    color: #c00;
  }

}

#contact_message_error {
  padding-bottom: 10px;
}

@media screen and (min-width: 576px) { /* mobile */

  #contact_form {

    .form-inline {
      flex-flow: row wrap;
    }

    .form-group > label {
      flex: 32%;
      text-align: left;
      display: unset;
      color: #424770;
      font-size: 17px;
      font-weight: 500;
      font-family: Camphor, Open Sans, Segoe UI, sans-serif;
      text-transform: none;
    }

    .form-group label.error, .form-group label.error {
      color: #c1002b;
      font-size: 0.85rem;
      flex-basis: -moz-fit-content;
    }

    .form-group > input {
      //padding: 5px 20px 8px 13px;
      flex: 68%;
      width: 100%;
    }

    .form-group > select, .form-group > textarea {
      //padding: 5px 20px 8px 13px;
      flex: 68%;
      width: 100%;
    }


  }


  /*
  #contact_form {
    .form-group > label {
      color: #424770;
      font-size: 17px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 26px;
      margin-right: 20px;
      -ms-flex-item-align: center;
      align-self: center;
      -webkit-box-flex: 32%;
      -ms-flex: 32%;
      flex: 32%;
      text-transform: none;
    }

    .form-group > input {
      padding: 5px 20px 8px 13px;
      flex: 68%;
      width: auto;
    }


  }
  */

}


@media (min-aspect-ratio: 16/9) {
  #videoBG {
    /*width:100%;
    height: auto;
    object-fit: cover;*/
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
  }
}

@media (max-aspect-ratio: 16/9) {
  #videoBG {
    /*
    width:auto;
    height: 100%;
     */

    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
  }
}


@media (max-width: 767px) {
  #videoBG {
    display: none;
  }
  body {

    @media (max-width: 767px) {
      #videoBG {
        display: none;
      }
      body {
        /*
        background: url('poster.jpg');
        background-size: cover;
         */
      }
    }
  }
}

//$button-color: #f78e1e !important;
$button-color: #f0ad00 !important;

.navigation .btn-solid, .navigation.navbar-sticky, .navbar-style-2 {

  .btn-outline {
    border-color: $button-color;
    color: $button-color;

    &:hover {
      background-color: $button-color;
      color: #fff !important;
    }
  }

  .btn-solid {
    background-color: $button-color;
    border-color: $button-color;
  }

  .nav-link {
    &:hover {
      color: $button-color;
    }
  }

}


.nav-process.nav-circle .nav-link::before {
  background-color: $button-color;
}

.nav-tabs.nav-outlined .nav-link {
  border: 1px solid $button-color;
  color: $button-color;
}

.nav-tabs.nav-outlined .nav-link.active {
  background-color: $button-color;
  color: white !important;
}

.card {
  min-width:200px;
}
.card-body {
  height:475px;
  min-width:200px;
}

.faq-card {
  height: auto;
}

@media screen and (max-width: 1185px) {
  .card-body {
    height: 500px;
  }
  .faq-card {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .card-body {
    height: 570px;
  }
  .faq-card {
    height: auto;
  }
}

#features {
  img {
    height: 36px;
  }

  .text-info {
    color: #3f4650 !important;
  }
}

.section-high-converting {
  padding-top: 180px;
  border-top: 0;

}

.signup-section {


  p {
    font-size:1.5em;
    @media screen and (max-width: 767px) { /* mobile */
      padding-top: 40px;
    }
  }

  .wrapper {
    width: 100%;
    margin: 0 auto;

    span {
      color: white;
      margin-right: 20px;
      font-size: 1.0em;
    }

    input[type="text"] {
      border: 0 none;
      border-radius: 3px;
      color: #4b4b4b;
      font-size: 14px;
      margin: 0 10px;
      padding: 12px 10px;
      width:220px;
      clear: both;

      margin: 1em 0;
      box-sizing: border-box;


      @media screen and (max-width: 767px) { /* mobile */
        clear: both;
        width: 100%;
      }

    }

    button {
      cursor: pointer;
      background: $button-color;
      border: 0 none;
      border-radius: 5px;
      color: white;
      padding: 11px 15px;
      margin-left: 10px;
      width: 170px;
      font-size: 14px;
      font-family: proxima_nova_alt_ltbold;
      height: 46px;

      @media screen and (max-width: 767px) { /* mobile */
        clear: both;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 40px;
      }

    }

  }

}


// block quotes

@import url(https://fonts.googleapis.com/css?family=Bitter);

* {
  box-sizing: border-box;
}

blockquote {
  position: relative;
  display: inline-block;
  background: #eee;
  padding: 40px 30px 30px 60px;
  border-radius: 10px;
  font-family: 'Bitter', serif;
  color: #333;
  text-align: left;

  box-shadow: 1px 1px 1px #999,
  2px 2px 1px #999,
  3px 3px 1px #999;
  transition-duration: .3s;

  span.author {
    position: relative;
    display: block;
    text-align: right;
    margin-top: 5px;
    color: #999;

    i {
      position: relative;
      background: #eee;
      padding-left: 5px;
      font-style: italic;
      z-index: 5;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #ccc;
      z-index: 1;
    }
  }

  &:hover {
    box-shadow: 1px 1px 5px #999,
    2px 2px 6px #999,
    3px 3px 7px #999;
  }

  &::before {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    content: "\"";
    font-family: 'Bitter', serif;
    font-size: 120px;
    color: #999
  }
}

.quote-hero {

}


@media screen and (min-width: 576px) { /* mobile */
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 1rem;
  }

}

// end block block quotes


// mobile menu

.st-popup-close-button {
  color: #062041 !important
}

.st-nav .st-popup-close-button:after, .st-nav .st-popup-close-button:before {
  background: #062041 !important
}

.st-nav a.text-contact {
  color: #055ba6 !important;
}

.st-nav a.text-pricing {
  color: #f78e1e !important
}

.st-nav a.text-signin {
  color: #062041 !important
}

.navigation .navbar-toggler .icon-bar {
  background-color: #062041 !important;
}


// end mobile menu