/* Cookie-Consent Custom CSS */
.cc-theme-custom {
  &.cc-window {
    color: #fff;
  }

  &.cc-banner .cc-message {
    width: auto;
    background: $cc-banner-message-bg-color;
    padding: 20px 76px 20px 16px;
    border-radius: 10px;
    margin-right: -70px;
    margin-left: 10%;
  }

  .cc-link,
  .cc-link:visited {
    color: red;
  }

  .cc-btn {
    background: #0000ff;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #0000ff 0%,
      #99007c 100%
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #0000ff 0%,
      #99007c 100%
    );
    background: radial-gradient(ellipse at center, #0000ff 0%, #99007c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0000ff', endColorstr='#99007c',GradientType=1 );
    padding: 58px 0;
    color: red;
    border: 4px dotted red;
    border-radius: 100px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    height: 150px;
    width: 150px;
    padding: 0;
    line-height: 10;
  }

  .cc-btn:hover {
    background: #99007c;
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
