/* Define your primary colors */
$primary-color-1: #f0ad00;
$primary-color-2: #4b71fc;
$darker-blue: rgba(0, 31, 63);

/* Create the main gradient background */
.background {
  width: 100%;
  //height: 100vh;
  height: 100%;
  background: linear-gradient(to bottom left, $primary-color-1 30%, $primary-color-2 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 40%, $darker-blue 100%);
  }

  // on mobile
  @media (max-width: 767px) {
    height: auto;
    background: #fff;
    &::before {
      display: none;
    }
  }

}


.page-main {
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;

  .page-content {

    &.with-shadow {
      box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.2);
    }

    &.center {
      margin: auto;
      width: 100%;
    }
  }

  .container {
    h1 {
      position: absolute;
      top: -1rem;
    }

    padding-top: 2rem;
    padding-bottom: 2rem;
  }


  // on mobile
  @media (max-width: 767px) {
    .page-content {
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      &.with-shadow {
        box-shadow: none;
      }

      &.center {
        margin: 0;
      }
    }

    .container {
      h1 {
        position: static;
        top: 0;
        margin-bottom: 2rem;
      }
    }
  }

  // in desktop
  @media (min-width: 768px) {
  /*  .page-content {
      overflow-y: scroll;
    }*/
  }


}


.setup-card {

  padding: 2.5rem 2.5rem 0;

  input[type=email]:focus,
  input[type=password]:focus,
  input[type=text]:focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 113, 252, 0.25);
    border-color: $primary-color-2;
  }


  .sw-toolbar-bottom {
    justify-content: space-between;

    .sw-btn-group {
      justify-content: space-between;
      width: 100%;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }
  }

  button {
    &.sw-btn-next {
      width: 77px;
      flex: 0;
      background-color: $primary-color-2 !important;
      border-color: $primary-color-2 !important;
    }

    &.button-prev {
      background: none;
      background-color: #fff !important;
      border: none;
      color: #333;
      flex: 0;
      flex-basis: 70px;
    }

    &.button-skip {
      background: none;
      background-color: #fff !important;
      border: none;
      color: #333;
      flex: 0;
      flex-basis: 180px;
      margin-right: 20px;
    }

    /*    &.sw-btn-prev {
          flex: 0;


          background-color: #fff !important;
          border: none;
          color: #333;

          i {
            display: inline-block;
          }

        }*/

  }

  .section-heading {
    margin-bottom: 1.5rem;

    img {
      max-width: 15rem;
    }

    .progress-bar {
      background-color: $primary-color-2;
    }
  }

  .password-group {
    position: relative;

    ul {
      margin-left: .5rem;
      margin-top: .5rem;

      li {
        color: #333;
        font-size: 14px;
      }
    }

    .control-label {
      text-align: left !important;
      padding-bottom: 4px;
    }

    .progress {
      height: 3px !important;
    }

    .form-group {
      margin-bottom: 10px;
    }

    .show-pass {
      position: absolute;
      top: 21%;
      right: 8%;
    }

    .progress-bar-danger {
      background-color: #e90f10;
    }

    .progress-bar-warning {
      background-color: #ffad00;
    }

    .progress-bar-success {
      background-color: #02b502;
    }

    .fa-eye {
      color: #6d768b;
      cursor: pointer;
    }

    .ex-account p a {
      color: #f6086e;
      text-decoration: underline;
    }

    .fa-circle {
      font-size: 6px;
    }

    .fa-check {
      color: #02b502;
    }
  }


  @media (max-width: 767px) {
    padding: 0;
  }


}


.page-content {
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  justify-content: center;
  z-index: 1;
  //max-width: 34rem;
  max-width: 37rem;
  flex-direction: column;
  display: flex;
}



.payment-terms-warning {

  text-align: left;
  font-size: 13px;
  width: 300px;

  // on desktop only
    @media (min-width: 768px) {

      margin-right: -40px;

      a {

      }
    }


}


/*

!* Create the cutout window *!
.cutout-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; !* Adjust the window size as needed *!
  height: 200px; !* Adjust the window size as needed *!
  background: white; !* Color of the window *!
  border-radius: 10px; !* Rounded corners for the window *!
  z-index: 1; !* Place it above the gradient background *!
}

!* Style your content within the cutout window *!
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; !* Place it above the cutout window *!
  !* Add your content styles here *!


  max-width: 80%; !* Adjust the max-width as needed *!
  max-height: 80%; !* Adjust the max-height as needed *!
  overflow: auto; !* Enable scrolling if content overflows *!

}
*/
