section.section-payment {


  .payment-method-cont .form-group {
    border-bottom: 1px solid #d5d5d5;
    //margin-bottom: 15px;
    margin-bottom: 0;
  }

  .payment-errors-cont {
    display: none;
    margin-bottom: 15px;
  }

  .stripe-v3-form .form-control {
    height: 46px;
    border: 0;
    padding: 12px 12px;
  }

  label {
    display: block;
    height: auto;
    position: relative;
    width: auto;
    font-size: 13px;
    font-weight: 500;
    top: 5px;
  }

  .login-reminder {
    h1 {
      font-size: 16px;
      color: #ac8510;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  label.error {
    font-weight: bold;
    color: red;
    padding: 2px 8px;
    margin-top: 2px;
  }

  .order-complete-message {
    font-size: 18px;
    font-weight: 700;
  }

  .cart-widget-inline {
    margin-top: 50px;
  }

  .site-wrap {
    background-color: #f3f3f3;
  }

  h1 {
    font-size: 24px;
    font-weight: 800;

    a {
      font-size: 11px;
      text-transform: uppercase;
      display: block;
      clear: both;
      margin-top: 4px;
    }
  }

  input[type=text], input[type=tel] {
    background-color: #fff;
    color: #1c1c1e;
    border-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 1px solid #d5d5d5;
    border-bottom-color: rgb(213, 213, 213);
    padding: 0 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 50px;

  }


  input[type="text"], .checkout input[type="tel"] {
    border: 1px solid #bdbdbd;
    color: #000;
    border-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 46px;
    font-size: 17px;

    &.invalid {
      color: red;
    }

  }

  select {
    height: 50px;
  }

  .form-inline {

    .form-group:not(:first-child) {
      margin-left: 10px;
    }
  }

  .width-half {
    width: 300px;;


  }

  .width-full {
    width: 613px;
  }


  #form-submit-btn {
    background-color: #6b0007;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
  }


  .steps {

    display: flex; /* establish flex container */
    flex-direction: row; /* default value; can be omitted */
    flex-wrap: nowrap; /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */

    font-size: 20px;
    margin-bottom: 20px;

    .stepbox-cont {
      width: auto;

      span {
        font-weight: bold;
        color: #a19e98;
      }


      .stepbox {
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        border-radius: 5px;
        background-color: #a19e98;
        color: #f3f3f3;
        width: 26px;
        line-height: 26px;
        white-space: nowrap;
      }

    }

    .active {

      .stepbox {
        background-color: #6b0007;
      }

      span {
        color: #6b0007;

        a {
          color: #6b0007;

          &:visited {
            color: #6b0007;
          }
        }
      }

    }


    .current {

      .stepbox {
        background-color: #3f8128;
      }

      span {
        color: #3f8128;

        a {
          color: #3f8128;

          &:visited {
            color: #3f8128;
          }
        }
      }

    }


  }

  .shipping-methods {
    .shipping-method-tr {
      background-color: #fff;
    }
  }

  .credit-card-box {

    h1 {
      display: inline-block;
      float: left;
      margin-top: 5px;
    }

    img {
      display: block;
    }

    margin-top: 20px;
  }

  .payment-option {
    margin-bottom: 15px;
    // margin-top: 15px;
    //margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h4 {
      font-size: 1.1rem;
      margin-left: -19px;
      margin-top: 10px;
    }

  }

  #form-submit-btn {
    background-color: $primary-color-2;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
  }

  .cart-widget .button.submit:hover {
    background-image: linear-gradient(to bottom, $primary-color-2, $primary-color-2);
  }

  .cart-widget .button.submit {
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px 6px 6px 6px;
    background-color: $primary-color-2;
    color: #f3f3f3;
    height: 60px;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
  }

  .subtext {
    font-size: 14px;
    color: #a19e98;
    margin-top: 10px;
    text-align: right;
  }


  .ssl-logo-cart-checkout {
    img {
      max-width: 100px;
    }
  }


  .summary {
    h5 {
      font-weight: 400;
      color: #001a3e;
      font-size: 1.1rem;
      margin-top: 7px;
    }

    .border-bottom {
      border-bottom: 1px solid #d9e2ef !important;
    }


  }

  .change-addons {
    text-decoration: underline;
    color: $primary-color-2;
    font-size:11px;
  }

  .product-title {
    position: relative;
  }

  .change-addons {
    text-decoration: underline;
    color: #4b71fc;
    font-size: 11px;
    position: absolute;
    top: -12px;
    left: 80px;
    cursor: pointer;
  }


  // if not mobile
  @media (min-width: 768px) {

    .payment-method-cont-credit-card {
      display: flex;
      flex-wrap: wrap;
    }
    .summary, .master-payment-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      align-content: stretch;
    }

    .boxy {
      box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.15) !important;
      border-radius: 0.3rem !important;
      padding: 15px;


    }

  }



}


